body {
  background-color: white;
}
.Dashboard {
  @import '../../scss/bootstrap';

  background-color: $dashboardBackground;

  &__clientGradient {
    position: absolute;
    top: 0px;
    left: 0px;
    // background: var(--primary-blue);
    background: linear-gradient(
      180deg,
      var(--primary-blue) 0%,
      var(--primary-blue) 76px,
      rgba(249, 253, 255, 0.7) 100%
    );
    height: 200px;
    width: 100%;
    z-index: -1;
  }

  &__headerCard {
    // background: rgba(38, 153, 251, 0.7);
    // background: var(--primary-blue);
    // background: linear-gradient(180deg, var(--primary-blue) 0%, rgba(249, 253, 255, 0.7) 100%);
    padding-top: 95px;
    @include media-breakpoint-up(sm) {
      height: 260px;
    }
    @media screen and (min-width: 700px) {
      padding-top: 120px;
      background: linear-gradient(
        180deg,
        var(--primary-blue) 0%,
        var(--primary-blue) 92px,
        rgba(249, 253, 255, 0.7) 100%
      );
    }
  }

  &__mainContainerDiv {
    margin-bottom: 7rem;
  }

  &__profileImage {
    height: $spacer * 5;
    width: $spacer * 5;
    border-radius: 10px;
    cursor: pointer;
  }

  &__greenUpdateTop {
    position: fixed;
    bottom: 60px;
    left: 0px;
    width: 100%;
    display: flex;
    font-family: $medium;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 50px;
    color: #111;
    font-size: 12px;
    z-index: 20;
    margin-right: 10px;
    background: linear-gradient(248deg, rgb(247, 249, 252), rgb(228, 229, 234));
  }

  &__redUpdateTop {
    position: fixed;
    bottom: 60px;
    left: 0px;
    width: 100%;
    display: flex;
    font-family: $medium;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 50px;
    color: #111;
    font-size: 12px;
    z-index: 20;
    margin-right: 10px;
    background: linear-gradient(248deg, rgb(247, 249, 252), rgb(228, 229, 234));
  }

  &__updateIcon {
    background-color: #0ec12f;
    color: white;
    padding: 3px 9px;
    border-radius: 100px;
    margin-right: 10px;
    font-size: 12px;
  }

  &__dotRedNoti {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 6px;
    right: 11px;
    z-index: 10;
    border-radius: 100px;
    background-color: #d40a0a;
  }

  &__notificationIcon {
    position: absolute;

    right: 4%;
    color: white;
  }

  &__notificationIconSvg {
    font-size: 26px;
    color: white;
  }

  &__alertIcon {
    background-color: #d60004;
    color: white;
    padding: 3px 9px;
    border-radius: 100px;
    margin-right: 10px;
    font-size: 12px;
  }

  &__blueLink {
    margin-left: 10px;
    color: #197bff;
    font-size: 12px;
    font-family: $semiBold;
  }

  &__headingText {
    // font-weight: bold;

    font-family: 'Montserrat-Regular';
    line-height: 29px;
    color: $headingGrey;
    padding-top: 0.875rem;

    @include media-breakpoint-up(sm) {
      font-size: 1.75rem;
      padding-top: 1.25rem;
    }
  }

  &__todaysHits {
    width: 95%;
    height: 150px;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.7);
  }

  &__todaysHitsText {
    font-family: 'Montserrat-SemiBold';
    color: $headingGrey;
    line-height: $spacer * 1.25;
    font-size: x-large;

    @include media-breakpoint-down(md) {
      font-size: $spacer * 0.875;
    }
  }

  &__attendanceScrollable {
    overflow-x: auto;
    display: flex;
  }
  &__attendanceScrollable::-webkit-scrollbar {
    display: none;
  }

  &__innovation {
    background: #fff4ec;
    border-radius: 10px;
    width: 93%;
    display: flex;

    @include media-breakpoint-down(sm) {
      width: 100%;
      display: block;
    }
    // background: linear-gradient(90deg, #fcf9f8, #fff4ec);
  }

  &__innovationh4 {
    font-family: 'Montserrat-Bold';
    line-height: $spacer * 1.8125;
    // color: var(--primary-blue);
    color: #fe979c;
  }

  &__innovationh4span {
    color: #3e3b6fde;
  }

  &__innovationp {
    font-size: $spacer;
    color: #575381;
    line-height: $spacer * 1.8;
    width: 60%;
    letter-spacing: 0.49px;
    font-family: 'Montserrat-SemiBold';
  }

  &__innovationpBlack {
    font-size: $spacer * 0.85;
    color: #000;
    letter-spacing: 0.49px;
    font-family: 'Montserrat-SemiBold';
  }

  &__scrollableCard {
    min-width: 100%;
    min-height: 76px;
    display: flex;
    overflow-x: auto;
  }

  &__scrollableCard::-webkit-scrollbar {
    display: none;
  }

  &__scrollableCard > div {
    min-width: 220px;
    margin: 5px;
    background: rgb(254, 255, 233);
    background: linear-gradient(90deg, rgba(254, 255, 233, 1) 0%, rgba(247, 255, 128, 1) 100%);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
    border: 1px solid transparent;
    border-radius: 10px;
  }

  &__scrollableCard > div:first-child {
    background: rgb(241, 255, 230);
    background: linear-gradient(90deg, rgba(241, 255, 230, 1) 0%, rgba(181, 253, 127, 1) 100%);
  }

  &__scrollableCardHeading {
    font-size: $spacer * 1;
    color: $headingGrey;
    line-height: $spacer * 1.25;
    font-family: 'Montserrat-SemiBold';
    padding-bottom: 7px;

    @include media-breakpoint-down(md) {
      font-size: $spacer * 0.75;
      padding-bottom: 5px;
    }
  }

  &__scrollableCardText {
    font-size: $spacer * 0.625;
    font-family: 'Montserrat-Regular';
    color: $dashboardBlack;
  }

  &__attendanceCard {
    border-radius: 10px;
    border-left: 30px solid var(--lighter-blue);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
    width: 95%;
  }

  &__attendanceSubHeading {
    font-family: 'Montserrat-Regular';
    line-height: 16px;
    color: $dashboardBlack;
  }

  &__attendanceRecents {
    font-size: $spacer * 0.625;
    font-family: $semiBold;
    color: $dashboardBlack;
    line-height: 13px;
    width: 95%;
  }

  &__noticeBoard {
    position: relative;
    width: 93%;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
    border-radius: 10px;

    @include media-breakpoint-down(sm) {
      width: 95%;
    }
  }

  &__verticalDots {
    position: absolute;
    top: 15px;
    right: 5px;
  }

  &__noticeBoardText {
    font-size: large;
    color: $dashboardBlack;
    font-family: 'Montserrat-Medium';

    @include media-breakpoint-down(md) {
      font-size: $spacer * 0.75;
      line-height: 15px;
    }
  }

  &__notice {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    // width: 100%;
    margin-bottom: 10px;
  }

  &__noticeImage {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  &__noticeSubHeading {
    font-size: $spacer * 0.825;
    font-family: 'Montserrat-Light';
    color: $headingGrey;
    line-height: 13px;

    @include media-breakpoint-down(sm) {
      font-size: $spacer * 0.625;
    }
  }

  &__noticeText {
    background-color: #fff4a4;
    // background-color: #009ece8a;
    font-family: 'Montserrat-Medium';
    font-size: $spacer * 0.75;
    color: $headingGrey;
    line-height: 22px;
    border-radius: 0 0 5px 5px;
    opacity: 0.75;
  }

  &__noticeText1 {
    background-color: #fff4a4;
    // background-color: #009ece8a;
    font-family: 'Montserrat-Medium';
    font-size: $spacer * 0.75;
    color: $headingGrey;
    line-height: 22px;
    border-radius: 0 0 5px 5px;
    opacity: 0.75;
  }

  &__admissionsBlueText {
    color: var(--primary-blue);
    font-size: $spacer * 1.5;
    font-family: $bold;
    opacity: 0.5;
  }
  &_homeworkCreator {
    @include media-breakpoint-up(sm) {
      margin-left: 1%;
    }
  }

  &__attendanceLogo {
    width: 54px;
    @include media-breakpoint-up(sm) {
      width: 80px;
    }
  }

  &__admissionButtonContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__NoticeIcon {
    width: 82px;
    height: 76px;
  }

  &__footer {
    // position: absolute;
    // bottom: 10px;
    width: 100%;
    padding-bottom: 30px;

    @include media-breakpoint-down(md) {
      height: 40px;
      // bottom: 70px;
    }
    text-align: center;
  }

  &__footerText {
    font-family: 'Montserrat-Regular';
    font-size: $spacer * 0.75;
    color: rgba($color: $black, $alpha: 0.6);
    line-height: 15px;
  }
}
.HWscrollableCard {
  background: linear-gradient(103deg, #ffefeb, #feae96) !important;
  border: 0px !important;
  margin-left: 0px !important;
  min-height: 85px;
}
.HWscrollableCard2 {
  background: linear-gradient(103deg, #f9efff, #705a7e) !important;
  border: 0px !important;
  min-height: 85px;
}

.Dashboard__rechargeErrorIcon {
  font-size: 72px !important;
  color: rgba(173, 0, 0, 1);
}

@media screen and (min-width: 600px) {
  .HWscrollableCard2 {
    margin-left: 0px !important;
  }
  .Dashboard__rechargeErrorIcon {
    font-size: 90px !important;
    color: rgba(173, 0, 0, 1);
  }
}

.connectText {
  color: var(--primary-blue);
  text-decoration: none;
}

.callbackText {
  color: var(--primary-blue) !important;
  text-decoration: none;
  cursor: pointer;
}

.callbackText:hover {
  text-decoration: underline;
}

.connectWithUsText {
  font-family: 'Montserrat-Medium';
  font-size: 14px;
}

.react_chart {
  display: flex;
  width: 100%;
}
.noticeboard_img {
  text-align: right;
  padding-right: 40px;
  padding-left: 0px;
}
.noticeboard_heading {
  padding-top: 30px;
}
.admission_heroImage {
  width: 50%;
  margin: auto;
  text-align: center;
}

.admission_cards {
  width: 93%;
}

.admission_button {
  padding-right: 15px;
  padding-left: 15px;
  background-color: white;
  border: 1px solid #7070708a;
}

.Dashboard__attendanceSubHeading {
  font-size: 12px;
}

.floatingChatButtonDashboard {
  position: fixed;
  cursor: pointer;
  outline: none;
  background-color: var(--primary-blue);
  border-radius: 100px;
  color: white;
  bottom: 75px;
  right: 20px;
  padding: 12px 25px;
  border: transparent;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.46);
  z-index: 50;
}

.floatingTestButtonDashboard {
  position: fixed;
  cursor: pointer;
  outline: none;
  background-color: var(--primary-blue);
  border-radius: 100px;
  color: white;
  top: 15px;
  right: 20px;
  padding: 4px 10px;
  border: transparent;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.46);
  z-index: 50;
}

.dotOnFloatingButton {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 55px;
  right: 26px;
  color: white;
  background-color: red;
  border-radius: 100px;
  width: 20px;
  min-width: fit-content;
  height: 20px;
  z-index: 60;
  font-family: 'Montserrat-Regular';
  font-size: 12px;
  padding: 3px;
}

.admission_charts {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.admission_charts::-webkit-scrollbar {
  display: none;
}

.clientLogoDashboard {
  width: 80px;
  height: 80px;
}

.savedSentCardImage {
  width: 50px;
  height: auto;
}

.connectWithUsText {
  margin-top: 23px;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .floatingChatButtonDashboard {
    right: 14px;
  }
}

@media screen and (max-width: 700px) {
  .noticeboard_img {
    width: 100%;
  }
  .react_chart {
    width: 100%;
    margin: auto;
  }
  .admission_cards {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .react_chart {
    width: 100%;
    margin: auto;
  }
  .admission_cards {
    width: 100%;
  }

  .admission_button {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media screen and (max-width: 450px) {
  .Dashboard__attendanceSubHeading {
    font-size: 12px;
  }
}

.dashboardLogoImage {
  display: block;
  margin: auto;
  width: 100%;
  justify-content: center;
  text-align: center;
}

.admissionsSubHeading {
  font-size: 12px;
  font-family: 'Montserrat-Regular';
  width: 88%;
  margin-bottom: '18px';
}

.nameAndProfilePic {
  margin-left: 0px;
  margin-right: 0px;
  width: 95%;
  transition-duration: 0.5s;
  transition-property: opacity;
}

.fillAdmissionFormButton {
  width: 30%;
  height: 40px !important;
  font-size: 15px !important;
  font-family: 'Montserrat-Regular';
}

.Dashboard_image {
  width: 92px;
  padding-right: 20px;
}

.scrollableCardHeading {
  width: 93%;
}

.aboutConnectContainer {
  width: 93%;
  margin: auto;
}

.addUserBatchContainer {
  display: flex;
  justify-content: flex-start;
}

.NumberOfUserContainer {
  width: 45%;
}

.chartContainer {
  width: 100% !important;
  border-radius: 10px !important;
  min-width: 260px !important;
}

.HWCdisplay {
  display: flex;
  flex-direction: column !important;
  margin: 14px 0px !important;
}

.admissionImage {
  max-width: 55px;
  width: 55px;
  height: 84px;
  position: relative;
  top: -29px;
}

// ========mobile screens
@media screen and (max-width: 600px) {
  .fillAdmissionFormButton {
    width: 53%;
  }

  .savedSentCardImage {
    width: 40px;
  }
  .HWCdisplay {
    flex-direction: row !important;
    margin: 5px 0px !important;
  }
  .chartContainer {
    width: 95% !important;
  }
  .addUserBatchContainer {
    justify-content: center;
  }
  .aboutConnectContainer {
    width: 95%;
  }
  .scrollableCardHeading {
    width: 95%;
  }
  .Dashboard_image {
    padding-right: 0px;
    width: 73px;
  }
  // .Dashboard__headerCard {
  //   margin-bottom: 60px;
  // }
  .zeroPaddingOnMobile {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .admission_charts {
    flex-direction: column;
  }
  .Dashboard__attendanceCard {
    width: 85%;
  }
  .admissionsSubHeading {
    width: 70%;
  }
}

.shareBtn {
  cursor: pointer;
}

.largeScreenPadding {
  padding: 1px;
}

// =============== very large screen
@media screen and (min-width: 1440px) {
  .Dashboard__headerCard {
    height: 388px;
  }
  .Dashboard__headingText {
    font-size: 38px !important;
  }

  .scrollableCardHeading {
    font-size: 28px !important;
    margin-bottom: 10px !important;
  }
  .Dashboard__attendanceSubHeading {
    font-size: 18px;
    line-height: 23px;
  }
  .DashboardCard_Image {
    padding-right: 80px;
  }
  .Dashboard__todaysHitsText {
    margin-bottom: 1.4rem;
    font-size: 28px;
  }
  .admissionsSubHeading {
    font-size: 18px;
    line-height: 23px;
  }
  .admission_button {
    font-size: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    margin-right: 15px;
  }
  .admissionImage {
    min-width: 90px;
    width: 90px;
    height: 137px;
    top: -45px;
  }
  .Dashboard__innovationpBlack,
  .Dashboard__innovationp {
    font-size: 19px;
  }
  .Dashboard__innovationp {
    margin-bottom: 35px;
  }
  .Dashbaord__innovationh4 {
    font-size: 38px;
  }
  .btnLetsGo {
    font-size: 24px;
  }
  .Dashboard__scrollableCardHeading {
    font-size: 19px;
  }
  .Dashboard__scrollableCardText {
    font-size: 14px;
  }
  .savedSentCardImage {
    width: 64px;
  }
  .Dashboard__attendanceRecents {
    font-size: 18px;
    line-height: 20px;
  }
  .Dashboard__attendanceLogo {
    width: 120px;
  }
  .personAddIcon {
    width: 35px;
    height: 35px;
  }
  .shareBtn {
    padding: 15px 30px !important;
    font-size: 19px !important;
  }
  .clientLogoDashboard {
    width: 6.5rem;
    height: 6.5rem;
  }
  .shareImage {
    width: 165px !important;
  }
  .fillAdmissionFormButton {
    font-size: 24px !important;
    height: 60px !important;
    font-family: 'Montserrat-Regular' !important;
  }
  .Dashboard__admissionButtonContainer button {
    font-size: 19px;
    height: 50px;
  }
  .Dashboard__noticeText {
    font-size: 18px !important;
  }

  .Dashboard__noticeText1 {
    font-size: 18px !important;
  }
  .Dashboard__NoticeIcon {
    width: 120px;
    height: 120px;
  }
  .contactIcons {
    width: 32px !important;
    height: 32px !important;
  }
  .connectWithUsText {
    font-size: 22px !important;
  }
  .largeScreenPadding {
    padding: 14px !important;
  }
}
// .zeroPaddingOnMobile {
//   margin-top: 6px;
// }

// we need media query for screens:
//1. greater than 1440px (min-width: 1440px) large laptop screens
//2. screens greater than 1700px (if necessary) (min-width: 1700px) 4k display and TV screens
//3. less than 600px (max-width: 600px) mobile phone devices

.Dashboard__brTagUsage {
  display: none;
}

@media screen and (max-width: 1440px) and (min-width: 550px) {
  .Dashboard__brTagUsage {
    display: block;
  }
}
