.PageHeader {
  @import '../../../scss/bootstrap';

  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: $white;

  &__title {
    font-size: $spacer * 1.25;
    font-family: 'Montserrat-Medium';
    line-height: $spacer * 1.5;
    color: $headingGrey;
    width: 60%;
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }
  &__input,
  &__input:focus {
    border: none;
    outline: none;
    width: 80%;
  }

  &__input::placeholder {
    font-family: 'Montserrat-Regular';
    color: $dashboardGrey;
  }

  &__text {
    font-size: 14px;
    font-family: $regular;
  }

  &__clientLogo {
    width: 40px;
    height: auto;
  }

  &__headerNewContainer {
    background-color: #f9f9f9;
    border-radius: 10px;
    width: 100%;
    @media screen and (min-width: 700px) {
      width: 85%;
      margin: auto;
    }
  }

  &__greeting {
    font-family: 'Montserrat-Regular';
    font-size: 16px;
    @media screen and (min-width: 1440px) {
      font-size: 20px;
    }
  }

  &__headerNew {
    top: 0px;
    left: 0px;
    position: fixed;
    transition-duration: 0.4s;
    transition-duration: background-color;
    background-color: var(--primary-blue);

    padding: 10px;
    width: 100%;
    z-index: 100;
    @media screen and (min-width: 700px) {
      padding: 18px;
    }
  }

  &__notiiconBadge {
    position: absolute;
    top: 5px;
    left: 36px;
    background-color: rgb(221, 2, 2);
    width: 16px;
    height: 16px;
    border-radius: 100px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }
}
.notFixedPageHeader {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;
}

.Parent_of_all_Pageheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.PageHeaderChild1 {
  flex: 1;
  flex-basis: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
// .Child2{
//   padding: 0rem 4vw 0rem 0rem ;
//   box-sizing: border-box;
// }
